/* color palette from <https://github.com/vuejs/theme> */
:root {
  --vt-c-white: #ffffff;
  --vt-c-white-soft: #f8f8f8;
  --vt-c-white-mute: #f2f2f2;

  --vt-c-black: #181818;
  --vt-c-black-soft: #222222;
  --vt-c-black-mute: #282828;

  --vt-c-indigo: #323031;

  --vt-c-divider-light-1: rgba(60, 60, 60, 0.29);
  --vt-c-divider-light-2: rgba(60, 60, 60, 0.12);
  --vt-c-divider-dark-1: rgba(84, 84, 84, 0.65);
  --vt-c-divider-dark-2: rgba(84, 84, 84, 0.48);

  --vt-c-text-light-1: var(--vt-c-indigo);
  --vt-c-text-light-2: rgba(60, 60, 60, 0.66);
  --vt-c-text-dark-1: var(--vt-c-white);
  --vt-c-text-dark-2: rgba(235, 235, 235, 0.64);

  /* mis variables */
  --vt-green: #f1f6f1;
  --vt-purple: #804fdc;
  --vt-purple-soft: #b288ff;
  --vt-blue: #86a5d9;
}

/* semantic color variables for this project */
:root {
  --color-background: var(--vt-c-white);
  --color-background-soft: var(--vt-c-white-soft);
  --color-background-mute: var(--vt-c-white-mute);

  --color-border: var(--vt-c-divider-light-2);
  --color-border-hover: var(--vt-c-divider-light-1);

  --color-heading: var(--vt-c-text-light-1);
  --color-text: var(--vt-c-text-light-1);

  --section-gap: 160px;

  /* mis variables */
  --section-green: var(--vt-green);
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: var(--vt-c-white);
    --color-background-soft: var(--vt-c-black-soft);
    --color-background-mute: var(--vt-c-black-mute);

    --color-border: var(--vt-c-divider-dark-2);
    --color-border-hover: var(--vt-c-divider-dark-1);

    --color-heading: var(--vt-c-text-dark-1);
    --color-text: var(--vt-c-text-dark-2);

    --section-green: var(--vt-green);
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  font-weight: normal;
}

body {
  min-height: 100vh;
  color: var(--color-text);
  background: var(--section-green);
  transition:
    color 0.5s,
    background-color 0.5s;
  line-height: 1.6;
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: 15px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.green-section {
  background: var(--section-green);
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::selection {
  background: var(--vt-green, #f1f6f1);
  color: var(--background, #181818);
}
.container-fluid {
  --bs-gutter-x: none !important;
}
footer {
  color: var(--vt-blue);
}
footer span {
  color: var(--vt-purple-soft);
}

/* diseño al estilo chequea.la */

h1 {
  color: var(--vt-purple);
}
form-btn {
  background-color: var(--vt-purple),
}
form-btn:hover {
  background-color: var(--vt-purple-soft),
}

.navbar {
  background-color: #fff;
}
.navbar .navbar-brand {
  color: var(--vt-blue);
  text-decoration: none;
  transition-duration: 0.3s;
}
.navbar .navbar-brand:hover, .navbar .navbar-brand:active {
  color: var(--vt-purple-soft);
  text-decoration: none;
}
.navbar .navbar-brand i {
  color: var(--vt-purple-soft);
  transition: transform 0.3s ease-in-out;
}
.navbar .navbar-brand:hover {
  color: var(--vt-purple-soft);
}
.navbar .navbar-brand:hover .rotate {
  transition: 0.3s;
  transform: rotate(-10deg);
}

.form-control-sm {
  font-size: 0.6rem !important; /* Tamaño de fuente pequeño */
  margin: 0; /* Elimina el margen inferior */
}

p,
li {
  font-weight: 300;
}
h1.tophead {
  color: #804fdc;
}
i {
  margin-right: 8px;
}
.card {
  border: none;
  border-radius: 0.75rem;
}

.sexybtn {
  background-color: var(--vt-blue) !important;
  text-decoration: none;
  font-weight: 500  !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)  !important;
  transition-duration: 1s  !important;
  color: #ffffff  !important;
}
.sexybtn:hover {
  background-color: var(--vt-purple-soft)  !important;
  transition-duration: 1s !important;
}

